export type VehicleForTitle = Partial<{
  production_year: string | number;
  make: string;
  model: string;
  badge: string;
  series: string;
}> &
  Record<string, any>;

export const getVehicleTitle = (vehicle?: VehicleForTitle): string => {
  if (!vehicle) {
    return '';
  }

  return [vehicle.production_year, vehicle.make, vehicle.model, vehicle.badge, vehicle.series]
    .filter(Boolean)
    .join(' ');
};
