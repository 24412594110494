import { GetServerSideProps } from 'next';
import TestDrivePage from '../../components/TestDrive';
import { requireShowroomFeature } from '../../services/showrooms/requireShowroomFeature';

export default TestDrivePage;

export const getServerSideProps: GetServerSideProps = requireShowroomFeature(
  'feature_test_drives_enabled',
  async (context) => {
    return {
      props: {},
    };
  },
);
