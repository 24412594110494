export const useScrollToElement = (elementOrSelector: string | HTMLElement, offset?: number) => (e?: any) => {
  if (e && typeof e.preventDefault === 'function') {
    e.preventDefault();
  }

  scrollToElement(elementOrSelector, offset);
};

export const scrollToElement = (elementOrSelector: string | HTMLElement, offset: number = 76) => {
  let element: HTMLElement;
  if (typeof elementOrSelector === 'string') {
    element = document.querySelector(elementOrSelector);
  } else {
    element = elementOrSelector;
  }

  if (!element) {
    console.error(`unable to find element`);
    return;
  }

  const bodyRect = document.body.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  const top = elementRect.top - bodyRect.top;

  window.scrollTo(0, top - offset);
};
