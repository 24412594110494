import { useRouter } from 'next/router';
import { useShowroom } from '../showrooms/useShowroom';
import { useShowroomSettings } from '../showrooms/useShowroomSettings';

export type Meta = {
  title: string;
  description?: string;
  image?: string;
  addTitlePrefix?: boolean;
};

export const usePageMeta = (props: Meta) => {
  const showroom = useShowroom();
  const showroomSettings = useShowroomSettings(['default_og_image']);
  const router = useRouter();

  const title = props.addTitlePrefix && showroom.name ? `${showroom.name} | ${props.title}` : props.title;

  const url = typeof window !== 'undefined' && window.location.origin + router.asPath;

  const image = props.image || showroomSettings.default_og_image;

  return (
    <>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="description" content={props.description} />
      <meta property="og:description" content={props.description} />

      {image && <meta property="og:image" content={image || showroomSettings.default_og_image} />}

      {url && <meta property="og:url" content={url} />}
    </>
  );
};
