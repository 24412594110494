export const ausPhoneValidationPattern =
  /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;

export const nzPhoneValidationPattern = /^(?:(?:\+|00)64[-.\s]?)?(?:\d[-.\s]?){1,3}?(?:\d[-.\s]?){4,8}\d$/;

export const getPhoneValidationPattern = (countryCode: string) => {
  const validators = {
    AU: ausPhoneValidationPattern,
    NZ: nzPhoneValidationPattern,
  };

  return validators[countryCode] || /.*/g;
};
