import { getImagekitUrl } from './getImagekitUrl';
import { getImgxUrl } from './getImgxUrl';

/**
 * Returns a URL which is safe to use on both imgix and imagekit
 *
 * For context, Vyro started using imgix then switched to imagekit
 *
 * @param src
 * @param params an object of params such as `w` and `h`
 * @returns a url with the correct params for both CDNs
 */
export const getImageCdnUrl = (src: string, params: Record<string, string | number | boolean>) => {
  return getImgxUrl(getImagekitUrl(src, params), params);
};
