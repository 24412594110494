import styles from "./index.module.scss";
import classnames from "classnames";

export type Props = {
  children?: React.ReactNode;
  className?: string;
  color?: "black" | "primary";
} & Record<string, any>;

export default function IconBackground(props: Props) {
  const { children, className, color = "primary", ...otherProps } = props;

  return (
    <div
      className={classnames(styles.iconBackground, styles[color], className)}
      {...otherProps}
    >
      <span className={styles.inner}>{children}</span>
    </div>
  );
}
