import classnames from 'classnames';
import styles from './index.module.scss';

export type Props = {
  className?: string;
  isOn?: boolean;
} & Record<any, any>;

export default function Switch(props: Props) {
  const { className, isOn, ...otherProps } = props;

  return (
    <div className={classnames(styles.switch, isOn && styles.isOn, className)} aria-hidden="true" {...otherProps}>
      <div className={styles.switchToggle} />
    </div>
  );
}
