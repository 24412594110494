import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';
import { getVehicleTitle } from '../../../../services/getVehicleTitle';
import List from '../../../List';
import styles from './index.module.scss';

type Props = {
  locationId: string;
  vehicleId: string;
  scheduledAt: string;
};

export default function Summary(props: Props) {
  const { locationId, vehicleId, scheduledAt } = props;

  const response = useQuery(
    gql`
      query TestDrivePageGetSummary($locationId: uuid!, $vehicleId: uuid!) {
        stocked_vehicles_by_pk(id: $vehicleId) {
          production_year
          make
          model
          badge
          series
        }
        locations_by_pk(id: $locationId) {
          name
          address
        }
      }
    `,
    {
      variables: {
        locationId,
        vehicleId,
      },
      skip: !locationId || !vehicleId,
    },
  );
  const location = response.data?.locations_by_pk;
  const vehicle = response.data?.stocked_vehicles_by_pk;

  return (
    <div className={styles.summary}>
      <List
        size="large"
        items={[
          {
            name: 'Location',
            description: location?.address?.formatted_address,
          },
          {
            name: 'Car',
            description: getVehicleTitle(vehicle),
          },
          {
            name: 'Time',
            description: dayjs(scheduledAt).format('dddd, MMMM D, YYYY [at] h:mm A'),
          },
        ]}
      />
    </div>
  );
}
