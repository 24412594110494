import classnames from 'classnames';
import Container from '../Container';
import styles from './index.module.scss';

import { useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { useShowroomSettings } from '../../services/showrooms/useShowroomSettings';
import Button from '../Button';
import Expandable from '../Expandable';
import Markdown from '../Markdown';
import { usePricingCodes } from '../../services/pricingCodes/usePricingCodes';

export type Props = {
  children?: React.ReactNode;
  className?: string;
} & Record<string, any>;

export default function CurrentPromoBanner(props: Props) {
  const { children, className, ...otherProps } = props;

  const showroomSettings = useShowroomSettings(['promo_banner']);

  const [isReadMoreOpen, setIsReadMoreOpen] = useState(false);

  if (!showroomSettings.promo_banner) {
    return null;
  }

  const { href, content, size, read_more, background, colour } = showroomSettings.promo_banner;

  const { pricingCode } = usePricingCodes();

  const isCurrentUrlMatchingPromo: boolean = typeof window !== 'undefined' && window.location.pathname === href;

  return (
    <a
      href={href}
      className={classnames(
        styles.currentPromoBanner,
        size === 'large' && styles.isLarge,
        className,
        isCurrentUrlMatchingPromo && styles.disabledLink,
      )}
      style={{
        background: background || '#000',
        color: colour || '#fff',
      }}
      {...otherProps}
    >
      <Container>
        <Markdown className={styles.content} variables={{ pricingCode: pricingCode?.code }}>
          {content}
        </Markdown>

        {typeof read_more === 'string' && !!read_more.trim() && (
          <>
            <button
              className={styles.readMoreToggle}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setIsReadMoreOpen(!isReadMoreOpen);
              }}
            >
              {isReadMoreOpen ? 'Less' : 'More'} info
              {isReadMoreOpen ? (
                <AiOutlineMinus className="inlineIcon suffix" />
              ) : (
                <AiOutlinePlus className="inlineIcon suffix" />
              )}
            </button>
            <Expandable isOpen={isReadMoreOpen}>
              <Markdown className={styles.readMore}>{read_more}</Markdown>
            </Expandable>
          </>
        )}
      </Container>
    </a>
  );
}
