import { PasswordPolicy, charsets } from 'password-sheriff';
export * from './phone';

export const emailValidationPattern =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const registrationNumberPattern = /^( ?[a-zA-Z0-9]){1,9}$/g;

export const dateValidationPattern =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

export const ausBSBValidationPattern = /\d{3}(-?|\s?)\d{3}/;
export const validateAusBSBNumber = (value: string) => {
  if (new RegExp(/\d{6}/).test(value)) {
    return true;
  }

  return 'Must be exactly six digits long. No spaces, no hyphens.';
};
export const validateAusBankAccountNumber = (value: string) => {
  if (new RegExp(/\d{7,12}/).test(value)) {
    return true;
  }

  return 'Must be 7-12 digits long. No spaces, no hyphens.';
};

export const validatePassword = (value: string) => {
  const lengthPolicy = new PasswordPolicy({
    length: { minLength: 8 },
  });

  try {
    lengthPolicy.assert(value);
  } catch (err) {
    return 'Your password should be at least 8 characters long.';
  }

  const characterPolicy = new PasswordPolicy({
    containsAtLeast: {
      atLeast: 3,
      expressions: [charsets.lowerCase, charsets.upperCase, charsets.numbers, charsets.specialCharacters],
    },
  });

  try {
    characterPolicy.assert(value);
  } catch (err) {
    return 'Your password should contain at least three numbers, uppercase characters or special characters.';
  }

  return true;
};

export const selectIsRequired = (message: string) => (value: any) => {
  if (value === '-1') {
    return message;
  }

  return true;
};

export const validateJSON = (value: string) => {
  try {
    JSON.parse(value);
    return true;
  } catch (err) {
    return `Invalid JSON - ${err.message}`;
  }
};
