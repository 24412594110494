import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import { useMediaQuery } from 'react-responsive';
import { useShowroomSettings } from '../../services/showrooms/useShowroomSettings';
import Card from '../Card';
import IconBackground from '../IconBackground';
import styles from './index.module.scss';

export type LoadingCardState = 'loading' | 'success' | 'failure';

export type Props = {
  className?: string;
  image?: React.ReactNode;
  state?: LoadingCardState;
  header?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  loadingImage?: React.ReactNode;
  loadingContent?: React.ReactNode;
  successImage?: React.ReactNode;
  successContent?: React.ReactNode;
  failureImage?: React.ReactNode;
  failureContent?: React.ReactNode;
  scrollOnStateChange?: boolean;
} & Record<string, any>;

export default function LoadingCard(props: Props) {
  const {
    className,
    image,
    state = null,
    header,
    children,
    footer,
    loadingImage,
    loadingContent,
    successImage = (
      <IconBackground>
        <AiOutlineCheck />
      </IconBackground>
    ),
    successContent,
    failureImage = (
      <IconBackground color="black">
        <IoMdClose />
      </IconBackground>
    ),
    failureContent,
    scrollOnStateChange = true,
    ...otherProps
  } = props;

  const cardRef = useRef(null);
  const loadingContentRef = useRef(null);
  const isTablet = useMediaQuery({
    query: '(max-width: 768px)',
  });

  const showroomSetting = useShowroomSettings(['square_logo']);

  const defaultImage = (() => {
    if (image) {
      return image;
    }

    if (showroomSetting.square_logo) {
      return <img src={showroomSetting.square_logo} />;
    }

    return null;
  })();

  useEffect(() => {
    if (!scrollOnStateChange || !isTablet) {
      return;
    }

    if (state === null) {
      window.scrollTo(0, 0);
    } else {
      if (loadingContentRef.current && typeof loadingContentRef.current.scrollIntoView === 'function') {
        loadingContentRef.current.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [state]);

  return (
    <Card
      ref={cardRef}
      className={classnames(
        styles.card,
        className,
        state === 'loading' && styles.isLoading,
        state === 'success' && styles.isSuccess,
        state === 'failure' && styles.isFailure,
      )}
      {...otherProps}
    >
      <div className={classnames(styles.image, styles.defaultImage)}>{defaultImage}</div>
      <div className={classnames(styles.image, styles.successImage)}>{successImage}</div>
      <div className={classnames(styles.image, styles.failureImage)}>{failureImage}</div>
      <div className={styles.header}>{header}</div>

      <div className={styles.defaultContent}>
        <div className={styles.content}>{children}</div>
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>

      <div className={styles.overlay}>
        <div className={styles.overlayContent}>
          <div ref={loadingContentRef} className={styles.loadingContent}>
            {loadingContent}
          </div>
          <div className={styles.successContent}>{successContent}</div>
          <div className={styles.failureContent}>{failureContent}</div>
        </div>
      </div>

      <div aria-hidden="true" className={styles.loader} />
    </Card>
  );
}

export type LoadingCardStatefulContentProps = {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  children?: React.ReactNode;
};

LoadingCard.StatefulContent = function LoadingCardStatefulContent(props: LoadingCardStatefulContentProps) {
  const { title, subTitle, children } = props;

  return (
    <div className={styles.statefulContent}>
      {title && <p className={styles.title}>{title}</p>}
      {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
      {children && <div className={styles.body}>{children}</div>}
    </div>
  );
};
