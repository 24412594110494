export const getImgxUrl = (src: string, _params: Record<string, string | number | boolean>) => {
  if (!src) {
    return null;
  }

  const url = new URL(src);

  const params = {
    auto: 'format',
    ..._params,
  };

  Object.keys(params).forEach((key) => {
    url.searchParams.set(key, params[key]);
  });

  return url.toString();
};
