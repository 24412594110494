export const getImagekitUrl = (src: string, _params: Record<string, string | number | boolean>) => {
  if (!src) {
    return null;
  }

  let baseUrl = '';
  if (src.substring(0, 1) === '/') {
    baseUrl = window.location.origin;
  }

  const url = new URL(baseUrl + src);

  const params = {
    f: 'auto',
    ..._params,
  };

  const stringifiedParams = Object.keys(params)
    .map((key) => {
      const value = params[key];

      return `${key}-${value}`;
    })
    .join(',');

  url.searchParams.set('tr', stringifiedParams);

  return url.toString();
};

/**
 * NextJS Image Loader
 *
 * @see https://nextjs.org/docs/api-reference/next/image
 *
 * @example
 *  <Image loader={imageKitLoader} src={...} />
 */
export const imageKitLoader = ({ src, width, quality, ...rest }) => {
  return getImagekitUrl(src, {
    width,
    quality,
  });
};
