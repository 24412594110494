import dayjs from 'dayjs';
import ReactCalendar, { CalendarProps as ReactCalendarProps } from 'react-calendar';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import styles from './index.module.scss';
import classnames from 'classnames';

interface Props extends ReactCalendarProps {
  name: string;
  rules?: ControllerProps['rules'];
}

export default function Calendar(props: Props) {
  const { name, rules, className, ...otherProps } = props;

  const form = useFormContext();

  const today = dayjs();
  const getTileClassName = ({ date, view }: { date: Date; activeStartDate: Date; view: string }) => {
    let classNames = [styles.tile];

    if (view === 'month') {
      if (dayjs(date).isSame(today, 'day')) {
        classNames = [...classNames, styles.today];
      }
      if (dayjs(date).isBefore(today, 'day')) {
        classNames = [...classNames, styles.past];
      }
    }

    return classNames.join(' ');
  };

  return (
    <Controller
      name={name}
      control={form.control}
      rules={rules}
      render={({ field }) => (
        <ReactCalendar
          {...otherProps}
          onChange={field.onChange}
          value={field.value}
          className={classnames(styles.calendar, className)}
          tileClassName={getTileClassName}
        />
      )}
    />
  );
}
