import axios from 'axios';
import dayjs from 'dayjs';

export const getAvailability = async (locationId: string, date: string) => {
  const availabilityResponse = await axios.request({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_TEST_DRIVES_API_URL}/availability`,
    params: {
      locationId,
      date: dayjs(date).format('YYYY-MM-DD'),
    },
  });

  return availabilityResponse.data.availability;
};
