import classnames from 'classnames';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import styles from './index.module.scss';

export type Props = {
  className?: string;
  size?: number;
};

export default function LoadingIcon(props: Props) {
  const { className, size } = props;

  return (
    <span className={classnames(styles.loadingIcon, className)}>
      <AiOutlineLoading3Quarters size={size} />
    </span>
  );
}
