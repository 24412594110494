import classnames from 'classnames';
import { size } from 'lodash';
import React, { useEffect, useRef } from 'react';
import FormField from '../FormField';
import styles from './index.module.scss';

export type Props = {
  children?: React.ReactNode;
  className?: string;
  defaultValue?: string;
  errors?: any;
  helpText?: React.ReactNode;
  description?: React.ReactNode;
  label?: string;
  name?: string;
  options: Array<{
    label: React.ReactNode;
    value: string;
    description?: React.ReactNode;
    isHidden?: boolean;
    isDisabled?: boolean;
    price?: string;
  }>;
  register?: any;
  rules?: any;
  setValue?: any;
  watch: any;
  scroll?: boolean;
  layout?: 'list' | 'compact';
  size?: 'small' | 'normal';
  allowUnselect?: boolean;
} & Record<string, any>;

export default function RadioList(props: Props) {
  const {
    children,
    className,
    defaultValue,
    errors,
    helpText,
    description,
    label,
    name,
    options = [],
    register,
    rules,
    formFieldProps,
    watch,
    layout,
    setValue,
    allowUnselect,
    scroll = false,
    SelectedIcon,
    size,
    ...otherProps
  } = props;

  const clear = () => {
    setValue(name, null);
  };

  const hasErrors = errors && !!errors[name];

  const selectedValue = watch(name) || defaultValue;
  const isSelected = (value: string): boolean => {
    return selectedValue === value;
  };

  const wrapperRef = useRef(null);
  useEffect(() => {
    if (typeof wrapperRef.current?.scrollTo === 'function') {
      window.setTimeout(() => {
        wrapperRef.current?.scrollTo(0, 0);
      }, 100);
    }
  }, [selectedValue]);

  return (
    <FormField
      label={label}
      description={description}
      name={name}
      helpText={helpText}
      errors={errors}
      {...formFieldProps}
    >
      <div
        ref={wrapperRef}
        className={classnames(
          styles.checkboxListWrapper,
          scroll && styles.scroll,
          layout === 'compact' ? styles.compactLayout : styles.listLayout,
          size === 'small' ? styles.small : styles.normal
        )}
      >
        <div
          className={classnames(
            styles.checkboxList,
            scroll && styles.scroll,
            hasErrors && styles.hasErrors,
            otherProps.options_layout && styles[otherProps.options_layout],
          )}
        >
          {options.map((op) => (
            <label
              key={op.value}
              className={classnames(
                styles.option,
                op.isHidden && styles.isHidden,
                isSelected(op.value) && styles.isSelected,
                op.isDisabled && styles.isDisabled,
              )}
              data-test={op.value}
            >
              <div className={styles.content}>
                <div className={styles.body}>
                  {SelectedIcon && isSelected(op.value) &&
                    <div className={styles.icon}> 
                      <SelectedIcon />
                    </div>
                  }                  
                  <div className={styles.label}>{op.label}</div>
                  {op.description && <div className={styles.description}>{op.description}</div>}
                </div>

                {op.price && <div className={styles.price}>{op.price}</div>}
              </div>

              <input
                className={styles.input}
                type="radio"
                value={op.value}
                defaultChecked={op.value === defaultValue}
                {...otherProps}
                {...register(name, rules)}
                onClick={(e) => {
                  if (isSelected(op.value) && allowUnselect) {
                    e.preventDefault();
                    clear();
                  }
                }}
              />
            </label>
          ))}
        </div>
      </div>
    </FormField>
  );
}
