import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { useFormContext } from 'react-hook-form';
import { useAuth } from '@vyro-x/react-auth';
import { emailValidationPattern, getPhoneValidationPattern } from '../../../../services/formValidators';
import { useGeoLocation } from '../../../../services/geoLocation/useGeoLocation';
import {
  TestDriveContactDetailsGetUserQuery,
  TestDriveContactDetailsGetUserQueryVariables,
} from '../../../../types/graphql';
import Expandable from '../../../Expandable';
import Input from '../../../Input';
import { PhoneInput } from '../../../Input/PhoneInput';
import Toggle from '../../../Toggle';
import styles from './index.module.scss';

export default function ContactDetails({ locationId }: { locationId: string }) {
  const { register, setValue, reset, watch, getValues } = useFormContext();

  const { hasuraUserId } = useAuth();

  const locationResponse = useQuery(
    gql`
      query TestDriveContactDetailsGetLocation($locationId: uuid!) {
        locations_by_pk(id: $locationId) {
          name
          address
        }
      }
    `,
    {
      variables: {
        locationId,
      },
      skip: !locationId,
    },
  );
  const location = locationResponse.data?.locations_by_pk;

  const userResponse = useQuery<TestDriveContactDetailsGetUserQuery, TestDriveContactDetailsGetUserQueryVariables>(
    gql`
      query TestDriveContactDetailsGetUser($userId: uuid!) {
        users_by_pk(id: $userId) {
          id
          first_name
          last_name
          email
          phone
        }
      }
    `,
    {
      variables: {
        userId: hasuraUserId,
      },
      skip: !hasuraUserId,
    },
  );
  const user = userResponse.data?.users_by_pk;

  useEffect(() => {
    if (user) {
      reset({
        ...getValues(),
        user_id: user.id,
        primary_driver: user,
      });
    }
  }, [user]);

  return (
    <div className={styles.contactDetails}>
      <h4>Your details</h4>
      <DriverForm name="primary_driver" address={location?.address?.formatted_address} />
      <Toggle
        name="has_second_driver"
        label="Will you be bringing a second driver?"
        watch={watch}
        register={register}
        setValue={setValue}
      />

      <Expandable isOpen={watch('has_second_driver')}>
        <div style={{ paddingTop: '1.5rem' }}>
          <h4 className="hug">Second driver</h4>
          <DriverForm name="secondary_driver" autoComplete="off" isHidden={!watch('has_second_driver')} />
        </div>
      </Expandable>
    </div>
  );
}

type DriverFormProps = { name: string; autoComplete?: string; isHidden?: boolean; address?: string };

function DriverForm({ name, autoComplete, isHidden, address }: DriverFormProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { location } = useGeoLocation();

  return (
    <Row>
      <Col xs={12} md={7}>
        <Row>
          <Col xs={12} sm={6}>
            <Input
              name={`${name}.first_name`}
              label="First name"
              register={register}
              rules={isHidden ? {} : { required: 'Please enter your first name' }}
              errors={errors}
              autoComplete={autoComplete}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Input
              name={`${name}.last_name`}
              label="Last name"
              register={register}
              rules={isHidden ? {} : { required: 'Please enter your first name' }}
              errors={errors}
              autoComplete={autoComplete}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Input
              name={`${name}.email`}
              label="Email"
              register={register}
              errors={errors}
              rules={
                isHidden
                  ? {}
                  : {
                      required: 'Please enter a email address',
                      pattern: {
                        value: emailValidationPattern,
                        message: "That doesn't look like a valid email address",
                      },
                    }
              }
              autoComplete={autoComplete}
            />
          </Col>
          <Col xs={12} md={6}>
            <PhoneInput name={`${name}.phone`} label="Phone" autoComplete={autoComplete} />
          </Col>
        </Row>
      </Col>

      {!!address && (
        <Col xs={12} md={5}>
          <div className={styles.map}>
            {address && (
              <iframe
                width="100%"
                height="300"
                style={{ border: '0' }}
                loading="lazy"
                allowFullScreen
                src={`https://www.google.com/maps/embed/v1/place?key=${
                  process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY
                }&q=${encodeURIComponent(address)}`}
              ></iframe>
            )}
          </div>
        </Col>
      )}
    </Row>
  );
}
