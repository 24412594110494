import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';

interface Props {
  isOpen: boolean;
  children: React.ReactNode;
  collapsedHeight?: number;
}

export default function Expandable({ isOpen, children, collapsedHeight = 0 }: Props) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number | null>(null);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [children, isOpen]);

  return (
    <div
      className={classnames(styles.expandable, isOpen && styles.open)}
      style={{ height: isOpen ? contentHeight : collapsedHeight, opacity: isOpen ? 1 : collapsedHeight > 0 ? 1 : 0 }}
    >
      <div ref={contentRef}>{children}</div>
    </div>
  );
}
