import styles from './index.module.scss';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Button from '../Button';

export interface ListItem {
  name: React.ReactNode;
  description?: React.ReactNode;
}

export interface Props {
  className?: string;
  items: Array<ListItem>;
  size?: 'normal' | 'large';
  columns?: 1 | 2;
  maxHeight?: number | boolean;
  truncateDescription?: boolean;
}

export default function List(props: Props) {
  const { className, size = 'normal', columns = 1, items, maxHeight = 470, truncateDescription = true } = props;

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [offsetHeight, setOffsetHeight] = useState(null);
  const listRef = useRef(null);

  const canCollapse = !!maxHeight && offsetHeight > maxHeight;

  useEffect(() => {
    const value = listRef.current?.offsetHeight;
    if (value) {
      setOffsetHeight(value);
    }
  }, [listRef]);

  return (
    <>
      <ul
        data-columns={columns}
        className={classnames(
          styles.list,
          styles[size],
          canCollapse && isCollapsed && styles.isCollapsed,
          truncateDescription && styles.truncateDescription,
          className,
        )}
        style={{
          maxHeight: isCollapsed && canCollapse ? `${maxHeight}px` : 'none',
        }}
        ref={listRef}
      >
        {items.map((item, i) => (
          <li key={i} className={styles.item}>
            <div className={styles.name}>{item.name}</div>
            <div className={styles.description}>{item.description || '-'}</div>
          </li>
        ))}
      </ul>
      {canCollapse && (
        <div className={styles.expand}>
          <Button isText color="grey" onClick={() => setIsCollapsed(!isCollapsed)} type="button">
            {isCollapsed ? 'Show more' : 'Show less'}
          </Button>
        </div>
      )}
    </>
  );
}
