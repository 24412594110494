import { captureException } from '@sentry/nextjs';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AiOutlineWarning } from 'react-icons/ai';
import Calendar from '../../../Calendar';
import LoadingIcon from '../../../LoadingIcon';
import RadioList from '../../../RadioList';
import { getAvailability } from './getAvailability';
import styles from './index.module.scss';

export default function TimePicker() {
  const { watch, register, control, setValue } = useFormContext();

  const locationId = watch('location_id');
  const date = watch('date');

  const [isLoading, setIsLoading] = useState(false);
  const [availability, setAvalability] = useState([]);
  useEffect(() => {
    if (!locationId || !date) return;

    (async () => {
      setIsLoading(true);

      try {
        const res = await getAvailability(locationId, date);
        setAvalability(res);
      } catch (err) {
        captureException(err);
        setAvalability([]);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [locationId, date]);

  return (
    <div className={styles.timePicker}>
      <div className={styles.date}>
        <div className={styles.calendarWrapper}>
          <Calendar className={styles.calendar} name="date" minDate={new Date()} />
        </div>
      </div>

      <div className={styles.times}>
        {isLoading ? (
          <>
            <LoadingIcon className="inlineIcon" />
            Loading...
          </>
        ) : availability.length === 0 ? (
          <p className="hug">
            <strong>
              <AiOutlineWarning className="inlineIcon" />
              Please select a different date
            </strong>
            <br />
            Unfortunately, there are no time slots available on the {dayjs(date).format('DD/MM/YYYY')} for your chosen
            location.
          </p>
        ) : (
          <RadioList
            formFieldProps={{ className: 'hug' }}
            layout="list"
            name="scheduled_at"
            register={register}
            control={control}
            setValue={setValue}
            watch={watch}
            options={availability.map((op) => ({
              label: dayjs(op).format('hh:mm a'),
              value: op,
            }))}
          />
        )}
      </div>
    </div>
  );
}
