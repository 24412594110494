import { gql, useMutation, useQuery } from '@apollo/client';
import { captureException } from '@sentry/nextjs';
import { useAuth } from '@vyro-x/react-auth';
import { signUpSilently } from '@vyro-x/react-auth/lib/service';
import classnames from 'classnames';
import Head from 'next/head';
import { ReactNode, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AiOutlineArrowRight } from 'react-icons/ai';
import Container from '../../components/Container';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { useShowroom } from '../../services/showrooms/useShowroom';
import { useShowroomSettings } from '../../services/showrooms/useShowroomSettings';
import { usePageMeta } from '../../services/usePageMeta';
import { useScrollToElement } from '../../services/useScrollToElement';
import { useUserReferrer } from '../../services/useUserReferrer';
import {
  TestDrivePageGetLocationsQuery,
  TestDrivePageGetLocationsQueryVariables,
  TestDrivePageGetVehiclesQuery,
  TestDrivePageGetVehiclesQueryVariables,
  Test_Drives_Set_Input,
} from '../../types/graphql';
import { LoadingState } from '../../types/LoadingState';
import Button from '../Button';
import CurrentPromoBanner from '../CurrentPromoBanner';
import LoadingCard from '../LoadingCard';
import LocationPicker from '../LocationPicker';
import Modal from '../Modal';
import ContactDetails from './components/ContactDetails';
import Summary from './components/Summary';
import TimePicker from './components/TimePicker';
import VehiclePicker from './components/VehiclePicker';
import styles from './index.module.scss';

export default function TestDrivePage() {
  const pageMeta = usePageMeta({
    title: 'Book a test drive',
    description: 'Get into the driver’s seat. Book a test drive today.',
  });

  const showroom = useShowroom();

  const showroomSettings = useShowroomSettings(['enquiry_url']);

  const form = useForm({
    defaultValues: {
      location_id: null,
      stocked_vehicle_id: null,
      date: new Date(),
      scheduled_at: null,
      primary_driver: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
      },
      has_secondary_driver: false,
      secondary_driver: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
      },
      user_id: null,
    },
  });
  const { handleSubmit, watch, setValue, control, register } = form;

  const locationId = watch('location_id');
  const vehicleId = watch('stocked_vehicle_id');
  const scheduledAt = watch('scheduled_at');

  useEffect(() => {
    setValue('stocked_vehicle_id', null);
  }, [locationId]);
  useEffect(() => {
    setValue('scheduled_at', null);
  }, [vehicleId]);

  const canSubmit = locationId && vehicleId && scheduledAt;

  const scrollToStep2 = useScrollToElement('#step-2');
  const scrollToStep3 = useScrollToElement('#step-3');
  const scrollToStep4 = useScrollToElement('#step-4');

  useEffect(() => {
    if (locationId) {
      scrollToStep2();
    }
  }, [locationId]);
  useEffect(() => {
    if (vehicleId) {
      scrollToStep3();
    }
  }, [vehicleId]);
  useEffect(() => {
    if (scheduledAt) {
      scrollToStep4();
    }
  }, [scheduledAt]);

  const [updateUser] = useMutation(gql`
    mutation TestDrivePageUpdateUser($id: uuid!, $object: users_set_input!) {
      update_users_by_pk(pk_columns: { id: $id }, _set: $object) {
        id
      }
    }
  `);

  const [insertTestDrive] = useMutation(gql`
    mutation TestDrivePageInsertTestDrive($objects: [test_drives_insert_input!]!) {
      insert_test_drives(objects: $objects) {
        affected_rows
      }
    }
  `);

  const { data: locationData } = useQuery(
    gql`
      query GetTestDriveLocation($id: uuid!) {
        locations_by_pk(id: $id) {
          name
        }
      }
    `,
    {
      variables: {
        id: locationId,
      },
      skip: !locationId,
    },
  );

  const [state, setState] = useState<LoadingState>(null);
  const { isAuthenticated, hasuraUserId } = useAuth();
  const referrerId = useUserReferrer();

  const onSubmit = handleSubmit(async (data) => {
    try {
      setState('loading');

      const object: Test_Drives_Set_Input = {
        location_id: data.location_id,
        stocked_vehicle_id: data.stocked_vehicle_id,
        scheduled_at: data.scheduled_at,
        primary_driver: data.primary_driver,
        secondary_driver: data.has_secondary_driver ? data.secondary_driver : null,
        showroom_id: showroom.id,
        stage: 'scheduled',
      };

      let userId: Test_Drives_Set_Input['user_id'] = null;
      if (isAuthenticated) {
        userId = hasuraUserId;
      } else {
        const response = await signUpSilently({
          email: data.primary_driver.email,
          showroom_code: showroom.code,
        });
        userId = response?.user_id;
      }

      if (userId) {
        object.user_id = userId;

        await updateUser({
          variables: {
            id: userId,
            object: {
              first_name: data.primary_driver.first_name,
              last_name: data.primary_driver.last_name,
              phone: data.primary_driver.phone,
            },
          },
        });
      }

      await insertTestDrive({
        variables: {
          objects: [object],
        },
      });

      const locationName = locationData?.locations_by_pk?.name;

      window.location.href = `/test-drive/thank-you?driver=${
        data?.primary_driver.first_name
      }&location=${locationName}&scheduledAt=${encodeURIComponent(data.scheduled_at)}`;
    } catch (err) {
      setState('failure');
      captureException(err);
    }
  });

  const defaultVehicleListUrlResponse = useQuery(
    gql`
      query TestDrivePageGetDefaultVehicleListUrl($showroomId: uuid!) {
        vehicle_lists(where: { showroom_id: { _eq: $showroomId }, is_default: { _eq: true } }) {
          path
        }
      }
    `,
    {
      variables: { showroomId: showroom.id },
      skip: !showroom.id,
    },
  );

  const defaultVehicleListUrl = defaultVehicleListUrlResponse.data?.vehicle_lists[0]?.path ?? '/vehicles';

  const vehiclesResponse = useQuery<TestDrivePageGetVehiclesQuery, TestDrivePageGetVehiclesQueryVariables>(
    gql`
      query TestDrivePageGetVehicles($locationId: uuid!) {
        stocked_vehicles(
          where: { location_id: { _eq: $locationId }, is_available_for_test_drives: { _eq: true } }
          order_by: { featured_order: asc }
        ) {
          id
          production_year
          make
          model
          badge
          packs
          media
        }
      }
    `,
    {
      variables: {
        locationId,
      },
    },
  );

  const vehicles = vehiclesResponse.data?.stocked_vehicles ?? [];

  const enquiryUrl = showroomSettings?.enquiry_url || '/contact-us';

  // location picker options
  const locationsResponse = useQuery<TestDrivePageGetLocationsQuery, TestDrivePageGetLocationsQueryVariables>(
    gql`
      query TestDrivePageGetLocations($showroomId: uuid!) {
        locations(
          where: { showroom_id: { _eq: $showroomId }, is_available_for_test_drives: { _eq: true } }
          order_by: { name: asc }
        ) {
          id
          name
          address
        }
      }
    `,
    {
      variables: {
        showroomId: showroom.id,
      },
    },
  );

  const locations = locationsResponse.data?.locations ?? [];

  return (
    <div>
      <Head>{pageMeta}</Head>

      <Header />

      <CurrentPromoBanner />

      <main>
        <section>
          <Container>
            <h1 className="h2">Book your test drive</h1>
            <div className={styles.ctaGroup}>
              <Button className={styles.cta} href={enquiryUrl} target={'_blank'}>
                Enquire now
              </Button>
              <p className="smallGap">
                <strong>
                  Or call us, on <a href={`tel:${showroom?.phone}`}>{showroom?.phone}</a>.
                </strong>
              </p>
            </div>
          </Container>
        </section>
        <FormProvider {...form}>
          <form onSubmit={onSubmit}>
            <section>
              <Container>
                <div className={styles.steps}>
                  <Step index={1} label="Select location">
                    <LocationPicker enquiryUrl={enquiryUrl} locations={locations} />
                  </Step>

                  <Step index={2} label="Select model" isDisabled={!watch('location_id')}>
                    <VehiclePicker vehicles={vehicles} />
                  </Step>

                  <Step index={3} label="Select time" isDisabled={!watch('stocked_vehicle_id')}>
                    <TimePicker />
                  </Step>

                  <Step index={4} label="Contact details" isDisabled={!watch('scheduled_at')}>
                    <Summary locationId={locationId} vehicleId={vehicleId} scheduledAt={scheduledAt} />

                    <ContactDetails locationId={locationId} />
                  </Step>

                  <div className={styles.submit}>
                    <Button color="primary" type="submit" isLoading={state === 'loading'} isDisabled={!canSubmit}>
                      Book test drive
                      <AiOutlineArrowRight className="inlineIcon suffix" />
                    </Button>
                  </div>
                </div>
              </Container>
            </section>
          </form>
        </FormProvider>

        <Modal className={styles.loadingCard} isOpen={!!state} onClose={() => {}}>
          <LoadingCard
            state={state}
            loadingContent={
              <LoadingCard.StatefulContent title="Reserving your place" subTitle="This will just take a moment." />
            }
            failureContent={
              <LoadingCard.StatefulContent
                title="Something went wrong"
                subTitle={
                  <>
                    <p className="hug">We're sorry, something went wrong.</p>
                    <Button color="primary" size="small" onClick={() => setState(null)}>
                      Try again
                    </Button>
                  </>
                }
              />
            }
          />
        </Modal>
      </main>

      <Footer />
    </div>
  );
}

type StepProps = {
  children: ReactNode;
  index: number;
  label: ReactNode;
  isDisabled?: boolean;
};

function Step(props: StepProps) {
  const { children, label, index, isDisabled } = props;

  return (
    <div className={classnames(styles.step, isDisabled && styles.isDisabled)} id={`step-${index}`}>
      <h2 className="h3">
        <div className={styles.index}>
          <span>{index}</span>
        </div>
        {label}
      </h2>
      {!isDisabled && children}
    </div>
  );
}
