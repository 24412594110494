import { Media } from '@vyro-x/vehicle-order-types';
import { useFormContext } from 'react-hook-form';
import { getImageCdnUrl } from '../../../../services/getImageCdnUrl';
import Card from '../../../Card';
import { TestDrivePageGetVehiclesQuery } from '../../../../types/graphql';
import styles from './index.module.scss';

type PropsTypes = {
  vehicles: TestDrivePageGetVehiclesQuery['stocked_vehicles'];
};

export default function VehiclePicker({ vehicles }: PropsTypes) {
  const { watch, setValue } = useFormContext();

  const vehicleId = watch('stocked_vehicle_id');

  return (
    <div className={styles.vehiclePicker}>
      {vehicles.length === 0 && <p>There's currently no cars available at this location.</p>}
      {vehicles.map((vehicle) => {
        const featuredImage: Media = vehicle.media.find((media) => media.placement === 'featured');
        return (
          <Card
            key={vehicle.id}
            className={styles.vehicle}
            isActive={vehicleId === vehicle.id}
            onClick={() => {
              setValue('stocked_vehicle_id', vehicle.id);
            }}
          >
            <Card.Image image={<img src={getImageCdnUrl(featuredImage?.src, { w: 760 })} alt={featuredImage?.alt} />} />
            <p className={styles.title}>
              {vehicle.production_year} {vehicle.make} {vehicle.model}
            </p>
            <h3 className={styles.badge}>{vehicle.badge}</h3>
            <p className={styles.packs}>{vehicle.packs}</p>
          </Card>
        );
      })}
    </div>
  );
}
