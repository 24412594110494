import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useShowroom } from '../../services/showrooms/useShowroom';
import { Locations } from '../../types/graphql';
import RadioList from '../RadioList';
import styles from './index.module.scss';

type PropTypes = {
  enquiryUrl?: string;
  locations: Pick<Locations, 'id' | 'name' | 'address'>[];
  optionsClassName?: string;
  mapsClassName?: string;
  displayMap?: boolean;
};

export default function LocationPicker(props: PropTypes) {
  const { enquiryUrl, locations, optionsClassName, mapsClassName, displayMap = true } = props;

  const { control, register, watch, setValue } = useFormContext();

  const showroom = useShowroom();

  const locations_options = locations.map((location) => ({
    label: (
      <div className={styles.location}>
        <div className={styles.name}>{location.name}</div>
        <div className={styles.address}>{location.address?.formatted_address}</div>
      </div>
    ),
    value: location.id,
  }));

  const locationId = watch('location_id');

  const location = locations.find((location) => location.id === locationId);

  return (
    <div className={styles.locationPicker}>
      <div className={classnames(styles.options, optionsClassName)}>
        <RadioList
          formFieldProps={{ className: 'hug' }}
          layout="list"
          name="location_id"
          register={register}
          control={control}
          setValue={setValue}
          watch={watch}
          options={[
            ...locations_options,
            {
              label: (
                <div className={styles.location}>
                  <div className={styles.name}>Don’t see your location, or need help with the form?</div>
                  <div className={styles.address}>
                    Click here to <a href={enquiryUrl}>enquire</a>
                    {showroom?.phone && `, or call us (${showroom?.phone})`}.
                  </div>
                </div>
              ),
              value: '',
            },
          ]}
        />
      </div>

      {displayMap && (
        <div className={classnames(styles.map, mapsClassName)}>
          {location?.address?.formatted_address && (
            <iframe
              width="100%"
              height="300"
              style={{ border: '0' }}
              loading="lazy"
              allowFullScreen
              src={`https://www.google.com/maps/embed/v1/place?key=${
                process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY
              }&q=${encodeURIComponent(location.address.formatted_address)}`}
            ></iframe>
          )}
        </div>
      )}
    </div>
  );
}
