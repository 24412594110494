import classnames from "classnames";
import React, { useEffect } from "react";
import FormField from "../FormField";
import Switch from "../Switch";
import styles from "./index.module.scss";

export type Props = {
  children?: React.ReactNode;
  className?: string;
  defaultValue?: boolean;
  errors?: any;
  helpText?: React.ReactNode;
  label?: string;
  name?: string;
  register?: any;
  rules?: any;
  watch?: any;
  setValue?: any;
} & Record<string, any>;

export default function Toggle(props: Props) {
  const {
    children,
    className,
    defaultValue,
    errors,
    helpText,
    label,
    name,
    register,
    rules,
    formFieldProps,
    watch,
    setValue,
    ...otherProps
  } = props;

  const hasErrors = errors && !!errors[name];

  const isSelected = watch(name) as boolean;

  useEffect(() => {
    setValue(name, defaultValue);
  }, []);

  return (
    <FormField
      className={styles.formField}
      label={
        <>
          {label}
          <div
            className={classnames(styles.toggle, hasErrors && styles.hasErrors)}
          >
            <Switch className={styles.switch} isOn={isSelected} />

            <input
              id={name}
              name={name}
              className={styles.input}
              type="checkbox"
              {...otherProps}
              {...register(name, rules)}
            />
          </div>
        </>
      }
      name={name}
      helpText={helpText}
      errors={errors}
      {...formFieldProps}
    ></FormField>
  );
}
