import { Controller, useFormContext } from 'react-hook-form';
import ReactPhoneNumberInput, { Country, isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useGeoLocation } from '../../../services/geoLocation/useGeoLocation';
import { useShowroom } from '../../../services/showrooms/useShowroom';
import { Address } from '../../AddressAutoComplete';
import FormField from '../../FormField';
import { Props as InputProps } from '../index';
import styles from './index.module.scss';

export const PhoneInput = (props: InputProps) => {
  const { name, label, rules, helpText, formFieldProps = {}, ...inputProps } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const geo = useGeoLocation();
  const showroom = useShowroom();

  const defaultCountry = geo.location?.country || (showroom.address as Address)?.country_code;

  return (
    <FormField label={label} name={name} errors={errors} helpText={helpText} {...formFieldProps}>
      <Controller
        control={control}
        name={name}
        rules={{
          ...rules,
          validate: (value) => {
            if (value && !isValidPhoneNumber(value)) {
              return "That doesn't look like a valid phone number";
            }

            if (rules?.validate) {
              return rules.validate(value);
            }

            return true;
          },
        }}
        render={({ field }) => (
          <ReactPhoneNumberInput
            className={styles.phoneInput}
            defaultCountry={defaultCountry as Country}
            value={field.value}
            onChange={field.onChange}
            {...inputProps}
          />
        )}
      />
    </FormField>
  );
};
